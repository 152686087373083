<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Saldo Kredit</strong>
        <a-date-picker
          class="ml-4"
          style="width: 150px;"
          v-model="tgl1"
          :allowClear="false"
          format="DD-MM-YYYY"
          @change="filterDate"
        />
        <!-- <a-range-picker class="ml-3" :format="'DD-MM-YYYY'" @change="rangedofunction"/> -->
        <a-button
          type="button"
          class="btn btn-outline-warning ml-2"
          @click="generateLaporanHarian"
        >
          Print PDF
        </a-button>
        <a-button
              class="btn btn-outline-success ml-2"
              @click="getAllData(true)"
              >Refresh Data</a-button
            >
        <!-- <a-button
          type="button"
          class="btn btn-outline-primary ml-4"
          @click="connector()"
        >
          Tambah
        </a-button> -->
      </template>
      <a-table
        :columns="columns"
        :dataSource="data"
        :pagination="{
          hideOnSinglePage: true,
          defaultPageSize: 10,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['10', '20', '30'],
        }"
        size="small"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px;"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px;"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px;"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        ></a-icon>
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
        <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
        <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
        <span slot="tags" slot-scope="tags">
          <a-tag v-for="tag in tags" color="blue" :key="tag">{{ tag }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a class="text-warning" @click="sendToEdit(record)">
            <a-icon type="edit"></a-icon>
          </a>
          <a-divider type="vertical" />
          <a href="javascript:void(0)" class="text-danger" @click="showDeleteConfirm(record)">
            <a-icon type="delete"></a-icon>
          </a>
          <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
        </span>
        <span slot="tgl" slot-scope="text, record">
          {{ formatDate(record.tgl) }}
        </span>
        <span slot="boolean" slot-scope="text">
          {{ formatBoolean(text) }}
        </span>
        <span slot="sudah_cair" slot-scope="text">
          {{
            text === "0"
              ? "Belum Cair"
              : text === "1"
              ? "Sudah Cair"
              : text
              ? "Sudah Cair"
              : !text
              ? "Belum Cair"
              : text
          }}
        </span>
        <span slot="formatCurrency" slot-scope="text">
          {{ formatCurrency(text) }}
        </span>
        <span slot="addpercentage" slot-scope="text">
          {{ text + '%' }}
        </span>
        <span slot="jw" slot-scope="text">
          {{ text + ' Bulan' }}
        </span>
      </a-table>
    </a-card>
  </div>
</template>

<script>
/* Import Script */
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import { mapState } from 'vuex'
import JSPDF from 'jspdf'
import 'jspdf-autotable'
/* End Script Import Script */

/* Kolom atau header dari table */
const columns = [
  // {
  //   title: 'Action',
  //   scopedSlots: { customRender: 'action' },
  //   width: 0,
  //   fixed: 'left',
  // },
  {
    title: 'Tanggal',
    width: 40,
    dataIndex: 'tgl',
    scopedSlots: { customRender: 'tgl' },
    // width: 100,
  },
  {
    title: 'Rekening',
    dataIndex: 'rekening',
    width: 30,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekening.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Nama',
    dataIndex: 'nama',
    // fixed: 'left',

    width: 100,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.nama.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Alamat',
    dataIndex: 'alamat',
    // fixed: 'left',
    width: 140,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.alamat.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Plafond',
    width: 50,
    dataIndex: 'plafond',
    scopedSlots: { customRender: 'formatCurrency' },
    align: 'right',
    // width: 100,
  },
  {
    title: 'Suku Bunga',
    width: 40,
    dataIndex: 'sukubunga',
    scopedSlots: { customRender: 'addpercentage' },
    align: 'right',
    // width: 100,
  },
  {
    title: 'Jangka Waktu',
    width: 50,
    dataIndex: 'jw',
    scopedSlots: { customRender: 'jw' },
    align: 'right',
    // width: 100,
  },
  {
    title: 'Saldo Pokok',
    width: 50,
    dataIndex: 'saldo_pokok',
    scopedSlots: { customRender: 'formatCurrency' },
    align: 'right',
    // width: 100,
  },
  {
    title: 'Jth Tempo',
    width: 50,
    dataIndex: 'jthtmp',
    scopedSlots: { customRender: 'tgl' },
    // width: 100,
  },
]
/* End script Kolom atau header dari table */

/* Data dari table */
const data = []
/* End script Data dari table */

export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  /* Data variable vue */
  data() {
    return {
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      tgl1: moment(),
      tgl2: '',
      name: 'TableRegisterNasabah',
      visible: false,
      statusperkawinan: {
        K: 'Kawin',
        B: 'Belum Kawin',
        D: 'Duda',
        J: 'Janda',
      },
      jenisidentitas: {
        1: 'KTP',
        2: 'SIM',
        3: 'Pasport',
      },
      yatidak: {
        0: 'Tidak',
        1: 'Ya',
      },
      perorangan: {
        1: 'Perorangan',
        2: 'Non-Perorangan',
      },
      allMaster: [],
      masterstatuskawin: [],
      masterkelamin: [],
      data,
      columns,
      datenow: '',
    }
  },
  /* End Data variable vue */
  mounted: function () {
    const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-31
    localStorage.removeItem('tabungankueditkreditdata')
    // this.tgl1 = date
    this.tgl2 = date
    this.datenow = date.toString()
    this.getAllData()
    // this.getAgamaF()
  },
  methods: {
    moment,
    filterDate(date, dateString) {
      this.tgl1 = date
      this.getAllData()
    },
    connector() {
      // console.log('keanggotaan', keanggotaan)
      localStorage.setItem(
        'tabungankueditkreditdata',
        JSON.stringify({ id: '' }),
      )
      this.$router.push('/tabungannku/kredit/form')
    },
    rangedofunction(date, dateString) {
      // this.tgl1 = moment(dateString[0], 'DD-MM-YYYY').format('YYYY-MM-DD')
      // this.tgl2 = moment(dateString[1], 'DD-MM-YYYY').format('YYYY-MM-DD')
      // this.getAllData()
    },
    async getAllData(onclick = false) {
      // console.log('this.allMaster', this.allMaster)
      var imbuhan = this.tgl1 !== null && this.tgl1 !== '' ? '?tgl=' + this.tgl1.format('YYYY-MM-DD') : ''
      var response = await lou.customUrlGet2('report/kredit/saldokredit' + imbuhan)
      if (response) {
        this.data = response.data
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
      }
      // console.log('this.data', this.data)
    },
    sendToEdit(edata) {
      // var ndata = {}
      // for (let i = 0; i < this.columns.length; i++) {
      //   const element = this.columns[i].dataIndex
      //   ndata[element.toString().toLowerCase()] = edata[element]
      // }
      // console.log('edata', edata)
      localStorage.setItem(
        'tabungankueditkreditdata',
        JSON.stringify(edata),
      )
      this.$router.push('/tabungannku/kredit/form')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2(
            'report/kredit/saldokredit/' + deldata.id,
          )
          this.data = response.data
          this.getAllData()
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    findRelationData(data, mastername, key, datakey, label) {
      var target = this[mastername].findIndex((x) => x[key] === data[datakey])
      // console.log('data', data)
      // console.log('mastername', mastername)
      // console.log('key', key)
      // console.log('target', target)
      // console.log('this.allMaster[mastername][target]', this.allMaster[mastername][target])
      // console.log('this[mastername][target]', this[mastername][target])
      return target === -1
        ? 'Data tidak ditemukan'
        : this[mastername][target][label]
    },
    formatCurrency(v) {
      return v === '' || v === null ? 0 : lou.curency(v)
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    formatBoolean(v) {
      if (v) {
        return 'Ya'
      } else {
        return 'Tidak'
      }
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    generateLaporanHarian() {
      const doc = new JSPDF()
      var that = this
      var header = function(data) {
        // console.log('data', data)
        doc.setFontSize(7)
        // doc.setFontType('bold')
        doc.text('Page : ' + data.pageNumber, 186, 10)
        var systime = moment().format('DD-MM-YYYY hh:mm:ss')
        doc.text(systime + ' SYSTEM', 162, 15)

        doc.setFontSize(10).setFont(undefined, 'normal')
        doc.text(that.user.namabpr, 15, 25)
        // doc.setFontSize(10).setFont(undefined, 'bold')
        // console.log('this.user', that.user)
        // var text = 'Saldo Deposito.'
        // var xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2)
        // doc.text(text, 15, 25)
        // var text1 = 'Tabungan Tanaman'
        // var xOffset1 = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2)
        // doc.text(text1, 89, 29)
        doc.setFontSize(10).setFont(undefined, 'normal')
        var text2 = 'Tanggal : ' + moment().lang('id').format('DD MMMM YYYY')
        // var xOffset2 = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2)
        doc.text('Alamat BPR', 15, 30)
        doc.text('Saldo Kredit. ' + text2, 15, 35)
        // doc.setFontType('normal')
        // doc.text(header2, 20, 52)

        // doc.setDrawColor(255, 255, 255, 255)
        // doc.setLineWidth(2)
        // doc.line(20, 56, 555, 56)
        // Footer
        // var str = 'Page ' + doc.internal.getNumberOfPages()

        // doc.setFontSize(10)

        // // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        // console.log('doc.internal.getNumberOfPages()', doc.internal.getNumberOfPages())
        // var pageSize = doc.internal.pageSize
        // var pageHeight = pageSize.height
        //   ? pageSize.height
        //   : pageSize.getHeight()
        // doc.text(str, data.settings.margin.left, pageHeight - 10)
      }
      var ncolumn = []
      var ndatatable = []
      var exclude = ['Alamat']
      var include = ['Tanggal', 'Rekening', 'Nama', 'Plafond', 'Suku Bunga', 'Jangka Waktu', 'Saldo Pokok', 'Jatuh Tempo']
      this.columns.forEach(element => {
        if (!exclude.includes(element.title)) {
          var nobj = {
            dataKey: element.dataIndex,
            columnWidths: 100,
            title: element.title,
          }
          if (include.includes(element.title)) {
            nobj.cell = {
              styles: { halign: 'center' },
            }
          }
          ncolumn.push(nobj)
        }
      })
      var dataclonded = this.$g.clone(this.data)
      // var kreditsubtotal = 0
      // var debetsubtotal = 0
      // var kreditgrandtotal = 0
      // var debetgrandtotal = 0
      dataclonded.forEach(element => {
        // console.log('element.kredit', element.kredit)
        // console.log('element.debet', element.debet)
        var ntgl = element.tgl
        // console.log('ntgl', ntgl)
        // element.therealnominal = element.nominal !== null && element.nominal !== '' ? element.nominal : ''
        // element.therealdebet = element.debet !== null && element.debet !== '' ? element.debet : ''
        element.tgl = moment(ntgl, 'YYYY-MM-DD').format('DD-MM-YYYY')
        element.rekening = element.rekening !== null && element.rekening !== '' ? element.rekening : ''
        element.nama = element.nama !== null && element.nama !== '' ? element.nama : ''
        element.plafond = element.plafond !== null && element.plafond !== '' ? this.formatCurrency(element.plafond) : ''
        element.sukubunga = element.sukubunga !== null && element.sukubunga !== '' ? element.sukubunga + '%' : ''
        element.jw = element.jw !== null && element.jw !== '' ? element.jw + ' Bulan' : ''
        element.saldo_pokok = element.saldo_pokok !== null && element.saldo_pokok !== '' ? this.formatCurrency(element.saldo_pokok) : ''
        element.jthtmp = element.jthtmp !== null && element.jthtmp !== '' ? moment(element.jthtmp, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''
        // element.debet = element.debet !== null && element.debet !== '' ? this.formatCurrency(element.debet) : ''
        ndatatable.push(element)
      })
      // jika data sedikit testing untuk next page
      // dataclonded.forEach(element => {
      //   ndatatable.push(element)
      // })
      // var objtotal = { content: 'Data', keterangan: 'Sub Total', debet: this.formatCurrency(debetsubtotal), kredit: this.formatCurrency(kreditsubtotal), colSpan: 4, rowSpan: 1, bodyStyles: { halign: 'center', fontStyle: 'bold' } }
      // var objtotal1 = { content: 'Data', keterangan: 'Grand Total', debet: debetgrandtotal, kredit: kreditgrandtotal, colSpan: 4, rowSpan: 1, bodyStyles: { halign: 'center', fontStyle: 'bold' } }
      // ndatatable.push(objtotal)
      // console.log('objtotal', objtotal)
      // ndatatable.push(objtotal1)
      // console.log('ndatatable', ndatatable)
      // eslint-disable-next-line no-unused-vars
      var height = 0
      doc.autoTable({
        body: ndatatable,
        columns: ncolumn,
        columnStyles: {
          0: { halign: 'center' },
          1: { halign: 'center' },
          2: { halign: 'left' },
          3: { halign: 'right' },
          4: { halign: 'center' },
          5: { halign: 'center' },
          6: { halign: 'right' },
          7: { halign: 'center' },
        },
        theme: 'grid',
        styles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 7,
          lineWidth: 0.1,
          lineColor: [0, 0, 0],
        },
        margin: { top: 39 },
        showFoot: 'lastPage',
        beforePageContent: header,
        // didDrawPage: function (data) {
        //   height = data.table.height
        // },
        didParseCell: (data) => {
          data.cell.styles.halign = 'center'
          // var col = data.column.index
          // if (
          //   col === 0 ||
          //   col === 1 ||
          //   col === 4 ||
          //   col === 5 ||
          //   col === 7
          // ) {
          //   data.cell.styles.halign = 'center'
          // } else if (
          //   col === 3 ||
          //   col === 6
          // ) {
          //   data.cell.styles.halign = 'right'
          // }
          // var rows = data.table.body
          // if (data.row.index === rows.length - 1) {
          //   data.cell.styles.fontStyle = 'bold'
          //   data.cell.styles.halign = 'right'
          // }
          // console.log('data.row', data.row)
          // console.log('data.column', data.column)
          // console.log('data.section', data.section)
        },
        didDrawCell: (data) => {
          // console.log('data.cell', data.cell)
          // console.log('data.row', data.row)
          // console.log('data.column', data.column)
          // console.log('data.section', data.section)
        },
        bodyStyles: {
          cellPadding: 0.6,
          valign: 'middle',
        },
      })
      height = doc.lastAutoTable.finalY
      // console.log('height', height)
      doc.setFontSize(8).setFont(undefined, 'bold')
      // doc.text('Malang, ' + moment().format('DD-MM-YYYY'), 170, height + 5)
      // doc.text('Pembuat', 40, height + 15)
      // doc.text('Diperiksa', 100, height + 15)
      // doc.text('Menyetujui', 160, height + 15)
      // var pname = 'Nama Orang 1'
      // doc.text(pname, 38, height + 45).setFont(undefined, 'bold')
      // var pname1 = 'Nama Orang 2'
      // doc.text(pname1, 98, height + 45).setFont(undefined, 'bold')
      // var pname2 = 'Nama Orang 3'
      // doc.text(pname2, 158, height + 45).setFont(undefined, 'bold')
      // doc.text('CS', 45, height + 50)
      // doc.text('Kaops', 103, height + 50)
      // doc.text('Direksi', 163, height + 50)
      doc.output('dataurlnewwindow')
    },
  },
}
</script>

<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
